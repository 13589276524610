<template>
  <div class="rating-bar flex">
    <div
      v-for="index in 5"
      :key="`i${index}`"
      :class="clazz(index)"
      @click="rate(index)"
    ></div>
  </div>
</template>

<script>
import ArticleService from "@/services/article"
import { bus } from "@/main"

export default {
  name: "RatingBar",
  props: {
    articleHash: {
      type: String,
      default: "",
    },
    rating: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 20,
    },
    interactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      intRating: this.rating,
    }
  },
  computed: {},

  watch: {
    rating(val) {
      this.intRating = val
    },
  },

  methods: {
    clazz(index) {
      const tmp = ["rounded-full", "mr-2", "circle"]
      tmp.push(this.size === 20 ? "w-4 h-4" : "w-56px h-56px")
      tmp.push(this.intRating < index ? "bg-gray-300" : "bg-white")
      if (this.interactive === true) tmp.push("cursor-pointer")
      return tmp.join(" ")
    },
    rate(index) {
      if (
        this.interactive === false ||
        this.articleHash === undefined ||
        this.articleHash === ""
      )
        return
      this.intRating = this.intRating === index ? 0 : index
      ArticleService.rateArticle(this.articleHash, this.intRating).then(
        response => {
          alert("Bewertung wurde aktualisiert.")
          bus.$emit("article-rated", this.articleHash, this.intRating)
        },
        error => {
          alert("Fehler!")
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.circle {
  border: none;
  outline: none;
}
</style>

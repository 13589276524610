<template>
  <div
    class="searchInfoBar w-full flex flex-row border-b border-solid border-black"
    @click="showSearch"
  >
    <!--div class="mobileInfo">
      <p>Treffer: {{ matches }}</p>
      <RoundButton @onClick="reset" icon="expand" color="orange" />
    </div>
    <div class="col textFilter">
      <div class="icon search"></div>
      <BadgeButton v-if="searchActive"
        :text="searchObject.k"
        color="yellow"
        active=true
      />
      <BadgeButton v-else
        text="Nach Schlagwort suchen"
        active=false

      />
    </div-->
    <div class="tags w-full md:w-2/3 flex px-8 items-center mr-8">
      <div class="icon filter"></div>
      <div class="pl-4 flex">
        <BadgeButton
          v-if="searchActive"
          :text="searchObject.k"
          class="mr-2"
          color="yellow"
          active="true"
          @onClick="showSearch"
        />
        <BadgeButton
          v-for="tag in tags"
          class="mr-2"
          :tid="tag"
          :key="tag"
          color="yellow"
          active="true"
          @onClick="showSearch"
        />
        <BadgeButton
          v-if="fromActive || toActive"
          class="timeBubble"
          :text="fromText"
          color="yellow"
          active="true"
          @onClick="showSearch"
        />
      </div>
    </div>
    <div
      class="before:hidden lg:before:block w-24 md:w-1/3 stroke flex items-center justify-between px-8"
    >
      <p class="hidden md:block ">Treffer: {{ matches }}</p>
      <div
        class="icon close"
        @click.prevent.stop="$emit('onResetSearch')"
      ></div>
    </div>
  </div>
</template>

<script>
// import RoundButton from "@/components/RoundButton.vue";
import BadgeButton from "@/components/BadgeButton.vue"
import { bus } from "@/main"

export default {
  components: {
    BadgeButton,
    // RoundButton
  },
  props: {
    article: {
      type: Object,
    },
    matches: {
      type: Number,
    },
    searchObject: {
      type: Object,
    },
  },
  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ],
    }
  },
  computed: {
    tags() {
      return this.searchObject.t
    },
    searchActive() {
      return this.searchObject.k
    },
    fromActive() {
      return this.searchObject.dfy !== undefined
    },
    toActive() {
      return this.searchObject.dty !== undefined
    },
    fromText() {
      let timeString = ""
      if (this.fromActive && this.toActive) {
        if (this.searchObject.dfm !== undefined) {
          timeString += `${this.months[this.searchObject.dfm - 1]} ${
            this.searchObject.dfy
          }`
        } else {
          timeString += `${this.searchObject.dfy}`
        }

        timeString += " - "

        if (this.searchObject.dtm !== undefined) {
          timeString += `${this.months[this.searchObject.dtm - 1]} ${
            this.searchObject.dty
          }`
        } else {
          timeString += `${this.searchObject.dty}`
        }
      } else if (this.fromActive) {
        if (this.searchObject.dfm !== undefined) {
          timeString += `Von ${this.months[this.searchObject.dfm - 1]} ${
            this.searchObject.dfy
          }`
        } else {
          timeString += `Von ${this.searchObject.dfy}`
        }
      } else if (this.toActive) {
        if (this.searchObject.dtm !== undefined) {
          timeString += `Bis ${this.months[this.searchObject.dtm - 1]} ${
            this.searchObject.dty
          }`
        } else {
          timeString += `Bis ${this.searchObject.dty}`
        }
      }
      return timeString
    },
    toText() {
      if (this.toActive) {
        if (this.searchObject.dtm !== undefined) {
          return `Bis ${this.months[this.searchObject.dtm - 1]} ${
            this.searchObject.dty
          }`
        } else {
          return `Bis ${this.searchObject.dty}`
        }
      }
      return false
    },
  },
  methods: {
    showSearch() {
      bus.$emit("showSearch")
      //this.$router.push({ name: "details", params: { id: this.article._id } });
    },
    reset() {
      bus.$emit("reset")
    },
  },
}
</script>

<style scoped lang="scss">
.tags {
  position: relative;
  display: flex;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    height: auto;
    background: linear-gradient(to right, transparent, $grey_light);
  }
}
.searchInfoBar {
  position: relative;
  cursor: pointer;
  background: $grey_light;
  width: 100%;
  height: $lineHeightL;
  margin-top: $lineHeightL;
  & > div {
    background: $grey-light;
  }
  .mobileInfo {
    display: none;
  }
  .col button {
    font-size: 20px;
  }

  .textFilter,
  .timeFilter {
    button:not(.yellow) {
      background: transparent;
    }
  }

  @media (max-width: 1023px) {
    .col {
      display: none;
    }
    .mobileInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 20px;
    }
  }
  @media (max-width: 767px) {
    .mobileInfo {
      padding-right: 10px;
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.entryClass,on:{"click":_vm.onClick}},[_c('div',{staticClass:"header"},[_c('RatingBar',{staticClass:"mb-4 -mt-6",attrs:{"rating":_vm.article.rating}}),_c('p',[_vm._v(" Datum:"),_c('BadgeButton',{attrs:{"text":_vm.dateString,"color":"trans","active":"true"}})],1),_c('br'),(_vm.article.tags)?_c('p',[_c('span',[_vm._v("Thematik:")]),_vm._l((_vm.articleTags),function(tag,i){return _c('span',{key:tag},[_c('BadgeButton',{attrs:{"tid":tag,"color":"trans","active":"true","type":"short"}}),(i < _vm.articleTags.length - 1)?_c('span',[_vm._v(",")]):_vm._e()],1)})],2):_vm._e(),_c('br'),(
        _vm.article.type !== 'video' &&
          _vm.article.type !== 'audio' &&
          _vm.article.type !== 'image'
      )?_c('p',[_vm._v(" Seiten: "+_vm._s(_vm.article.pages)+" ")]):_vm._e()],1),(_vm.article.type !== 'video' && _vm.article.type !== 'audio')?_c('div',{staticClass:"imgWrapper"},[_c('img',{attrs:{"src":_vm.imgSrc}}),_c('RoundButton',{attrs:{"icon":"open","text":"Eintrag anzeigen"}})],1):_vm._e(),(_vm.article.type === 'video')?_c('div',{staticClass:"flex justify-center cursor-pointer items-center h-2/3"},[_vm._m(0)]):_vm._e(),(_vm.article.type === 'audio')?_c('div',{staticClass:"flex justify-center cursor-pointer items-center h-2/3"},[_vm._m(1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon video !w-48 !h-48 mt-16"},[_c('img',{attrs:{"src":require("../assets/icons/video.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon audio !w-48 !h-48 mt-16"},[_c('img',{attrs:{"src":require("../assets/icons/audio.svg"),"alt":""}})])
}]

export { render, staticRenderFns }
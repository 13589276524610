<template>
  <div id="imgList">
    <div
      :id="'img_' + index"
      class="imgWrapper"
      v-for="(image, index) in images"
      :key="image"
    >
      <!--div class="img" :style="{ backgroundImage: `url(${image})` }" @dblclick="$emit('onClick', i)"></div-->
      <img :src="image" />
      <RoundButton
        class="zoomButton"
        @onClick="$emit('onClick', index)"
        color="yellow"
        icon="zoom"
        text="Zoomansicht öffnen"
      />
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton"

export default {
  name: "ImageList",
  components: {
    RoundButton,
  },
  props: {
    id: {
      type: String,
    },
    images: {
      type: Array,
    },
    resourcePath: {
      type: String,
    },
  },
  data: () => ({}),
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
img {
  max-width: 100%;
  max-height: 100%;
}

.imgWrapper {
  position: relative;
  max-width: 420px;
  margin: 0 auto 9px;
  //max-height: 420px;
  //overflow: hidden;

  button {
    position: absolute;
    top: 9px;
    right: 9px;
  }
}

@media (min-width: 1281px) {
  img {
    //max-width: 33.3%;
  }
  .imgWrapper {
    max-width: 33.3%;
  }
}
</style>

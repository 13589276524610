<template>
  <div :class="entryClass" @click="onClick">
    <div class="header">
      <RatingBar :rating="article.rating" class="mb-4 -mt-6" />
      <p>
        Datum:<BadgeButton :text="dateString" color="trans" active="true" />
      </p>
      <br />
      <p v-if="article.tags">
        <span>Thematik:</span>
        <span v-for="(tag, i) in articleTags" :key="tag">
          <BadgeButton :tid="tag" color="trans" active="true" type="short" />
          <span v-if="i < articleTags.length - 1">,</span>
        </span>
      </p>
      <br />
      <p
        v-if="
          article.type !== 'video' &&
            article.type !== 'audio' &&
            article.type !== 'image'
        "
      >
        Seiten: {{ article.pages }}
      </p>
    </div>
    <div
      v-if="article.type !== 'video' && article.type !== 'audio'"
      class="imgWrapper"
    >
      <img :src="imgSrc" />
      <RoundButton icon="open" text="Eintrag anzeigen" />
    </div>
    <div
      v-if="article.type === 'video'"
      class="flex justify-center cursor-pointer items-center h-2/3"
    >
      <div class="icon video !w-48 !h-48 mt-16">
        <img src="../assets/icons/video.svg" alt="" />
      </div>
    </div>
    <div
      v-if="article.type === 'audio'"
      class="flex justify-center cursor-pointer items-center h-2/3"
    >
      <div class="icon audio !w-48 !h-48 mt-16">
        <img src="../assets/icons/audio.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
const S3_URL = process.env.VUE_APP_AWS_URL
//const S3_URL = 'https://ewh-archive-bucket.s3.eu-central-1.amazonaws.com/';
//const S3_URL = 'https://ewh-archive-dev.s3.eu-central-1.amazonaws.com/';
import BadgeButton from "@/components/BadgeButton.vue"
import RoundButton from "@/components/RoundButton.vue"
import RatingBar from "@/components/RatingBar.vue"

export default {
  components: {
    BadgeButton,
    RoundButton,
    RatingBar,
  },
  computed: {
    tagsString() {
      return this.article.tags.join(", ")
    },
    imgStyle() {
      return `backgroundImage: url(${this.imgSrc})`
    },
    imgSrc() {
      if (this.article.type === "image") {
        return `${S3_URL}${this.article.year || "XXXX"}/${this.article.hash}/${
          this.article.name
        }-s.jpg`
      } else {
        return `${S3_URL}${this.article.year || "XXXX"}/${this.article.hash}/${
          this.article.name
        }-000-s.jpg`
      }
    },
    dateString() {
      const parts = []
      if (this.article.day !== null) parts.push(this.article.day)
      if (this.article.month !== null) parts.push(this.article.month)
      parts.push(this.article.year)
      return parts.join(".")
    },
    entryClass() {
      return `entry`
    },
    isAdmin() {
      return this.layout === "admin"
    },
    articleTags() {
      return this.article.tags
        .filter(t => {
          const tmp = t.toUpperCase()
          return (
            !["PRI", "LIT", "BUS", "AUDIO", "VIDEO"].includes(tmp) &&
            !["IPHONE5", "IPHONE6", "IPHONE12PROMAX", "IPHONEXSMAX"].includes(
              tmp
            ) &&
            !tmp.startsWith("N_")
          )
        })
        .map(t => t.toUpperCase())
    },
  },
  props: {
    article: {
      type: Object,
    },
    layout: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClick() {
      this.$router.push({
        name: "details",
        params: { hash: this.article.hash },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.entry {
  position: relative;
  padding: 40px 10px 10px;
  height: 590px;
  width: 300px;
  flex: 0 0 auto;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  overflow: hidden;
  //cursor: pointer;

  p {
    display: inline-block;
    font-size: 14px;
    margin: 0 0 5px;
  }
  .header {
    margin-bottom: 5px;
  }
  .imgWrapper {
    position: relative;
    width: 100%;
    max-height: calc(100% - 70px);
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    img {
      position: relative;
      width: 100%;
      opacity: 1;
      //height: 100%;
      //background-repeat: no-repeat;
      //background-size: 100% auto;
      //background-position: center top;
      //background-size: calc(100% + 6px) auto;
      //background-position: -3px -3px;
    }
    button {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    &:hover {
      button {
        display: block;
      }
      img {
        opacity: 0.4;
      }
    }
  }

  .icon {
    &:hover {
      transform: scale(1.1);
      transition: all 0.3s;
    }
  }

  &.admin {
    padding: 10px;
    height: auto;
    width: 100%;
    img {
      display: none;
    }
  }
}
</style>

<template>
  <div class="zoom">
    <div ref="wrapper" class="wrapper">
      <div
        v-if="loadedRatio > 0 && loadedRatio < 1"
        style="height: 5px; background-color: #3c8658; color: white; text-align: center"
        :style="{ width: loadedRatio * 100 + '%' }"
      ></div>
      <panZoom
        @init="onInit"
        id="zoomer"
        class="zoomer"
        ref="panzoom"
        :options="{
          autocenter: true,
          bounds: true,
          boundsPadding: 0.1,
          minZoom: 0.2,
          maxZoom: 5,
          smoothScroll: true,
        }"
      >
        <div v-if="article.type === 'image'">
          <img
            v-show="isLoaded"
            ref="img"
            :src="`${path}${article.name}.jpg`"
            alt=""
            @load="onImgLoaded"
          />
        </div>

        <div v-else class="pdfWrapper">
          <pdf
            class="pdf"
            :src="`${path}${article.name}-${pdfPage}.pdf`"
            v-show="isLoaded"
            ref="pdf"
            @progress="loadedRatio = $event"
            @error="onError"
            @loaded="onLoaded"
          ></pdf>
        </div>
      </panZoom>
    </div>
    <div class="row subbar flex items-center h-full">
      <div class="tags w-1/3 px-8 h-full flex items-center">
        <p class="label"><span>Thematik:</span></p>
        <BadgeButton
          v-for="tag in article.tags"
          :tid="tag"
          :key="tag"
          color="trans"
          active="true"
          type="short"
        />
      </div>
      <div class="stroke w-1/3 px-8 h-full flex items-center">
        <p class="label"><span>Datum: </span>{{ dateString }}</p>
      </div>
      <div class="stroke buttons w-1/3 px-8">
        <span>
          <RoundButton
            @onClick="zoomIn"
            icon="minus"
            color="yellow"
            class="left mr-2"
            text="Verkleinern"
          />
          <RoundButton
            @onClick="zoomOut"
            icon="plus"
            color="yellow"
            class="left mr-2"
            text="Vergrößern"
          />
        </span>
        <!--RoundButton @onClick="zoomReset" icon="reset" color="yellow" /-->
        <!--RoundButton @onClick="$refs.pdf.print()" icon="down" color="green" /-->
        <RoundButton
          @onClick="$emit('onClose')"
          icon="close"
          text="Schließen"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton"
import BadgeButton from "@/components/BadgeButton"
import pdf from "vue-pdf"

export default {
  components: {
    pdf,
    RoundButton,
    BadgeButton,
  },
  data() {
    return {
      show: true,
      loadedRatio: 0,
      numPages: 0,
      rotate: 0,
    }
  },
  props: {
    img: {
      type: Number,
    },
    page: {
      type: Number,
    },
    path: {
      type: String,
    },
    article: {
      type: Object,
    },
  },
  computed: {
    pdfPage() {
      return `00${this.page - 1}`.slice(-3)
    },
    isLoaded() {
      return this.loadedRatio === 1
    },
    dateString() {
      const parts = []
      if (this.article.day !== null) parts.push(this.article.day)
      if (this.article.month !== null) parts.push(this.article.month)
      parts.push(this.article.year)
      return parts.join(".")
    },
  },
  methods: {
    zoomIn() {
      this.$refs.panzoom.$panZoomInstance.zoomTo(
        window.innerWidth / 2,
        window.innerHeight / 2,
        1.25
      )
    },
    zoomOut() {
      this.$refs.panzoom.$panZoomInstance.zoomTo(
        window.innerWidth / 2,
        window.innerHeight / 2,
        0.75
      )
    },
    zoomReset() {},
    onInit(instance, id) {
      //console.log(`${instance} ${id}`)
    },
    onError(error) {
      console.log(error)
    },
    onImgLoaded() {
      this.loadedRatio = 1
      this.$nextTick(() => {
        const elW = this.$refs.img.scrollWidth
        const elH = this.$refs.img.scrollHeight
        const ratio = (window.innerHeight - 95) / elH

        const offset = 10

        this.$refs.panzoom.$panZoomInstance.zoomAbs(elW / 2, offset, ratio)
        document.getElementById("zoomer").classList.add("active")
      })
    },
    onLoaded() {
      const _this = this
      setTimeout(() => {
        const elW = _this.$refs.pdf.$el.scrollWidth
        const elH = _this.$refs.pdf.$el.scrollHeight
        const ratio = (window.innerHeight - 95) / elH

        const offset = 10

        _this.$refs.panzoom.$panZoomInstance.zoomAbs(elW / 2, offset, ratio)

        document.getElementById("zoomer").classList.add("active")
      }, 500)
    },
  },
}
</script>

<style scoped lang="scss">
.zoom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 999;
  flex-direction: column;
  background: $grey;
  overflow: hidden;

  .load-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
  }

  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: $lineHeightL;
    //z-index: 50;
  }
  .zoomer {
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 1s;
    &.active {
      opacity: 1;
    }
  }
  .row {
    border-bottom: none;
    border-top: 1px solid black;
    position: absolute;
    height: $lineHeightL;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .tags {
    button {
      font-size: 16px;
    }
  }
}

.buttons {
  padding-right: 20px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .subbar {
    height: $offsetTwo !important;
    width: 100vw !important;
    flex-wrap: wrap;
    & > div {
      height: $lineHeightL !important;
      width: 50vw !important;
      flex: 0 0 auto;
      &.right {
        width: 100vw !important;
        border-top: 1px solid black;
        justify-content: center;
        &:before {
          display: none;
        }
      }
    }
    .label span {
      display: none;
    }
    .buttons {
      width: 100% !important;
      border-top: 1px solid black;
      &:before {
        display: none;
      }
    }
  }
}
.vue-pan-zoom-scene {
  //height: calc(100vh - $lineHeightL) !important;
}
</style>

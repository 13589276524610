const tags = [
  {
    key: "A",
    name: "Anhänge",
  },
  {
    key: "AU",
    name: "Audio",
  },
  {
    key: "BA",
    name: "Bank",
  },
  {
    key: "B",
    name: "Briefe",
  },
  {
    key: "B1",
    name: "Business 1",
  },
  {
    key: "B2",
    name: "Business 2",
  },
  {
    key: "B3",
    name: "Business 3",
  },
  {
    key: "D",
    name: "Dokumentation",
  },
  {
    key: "EL",
    name: "Einladung",
  },
  {
    key: "E",
    name: "E-Mails",
  },
  {
    key: "EV",
    name: "Events",
  },
  {
    key: "F",
    name: "Fax",
  },
  {
    key: "FM",
    name: "Fremde Manuskripte",
  },
  {
    key: "G",
    name: "Grafiken / Fotos",
  },
  {
    key: "GK",
    name: "Grußkarten",
  },
  {
    key: "H",
    name: "Handschrift",
  },
  {
    key: "I",
    name: "Internet",
  },
  {
    key: "IV",
    name: "Interview",
  },
  {
    key: "M",
    name: "Magazinartikel",
  },
  {
    key: "MR",
    name: "Manuskripte Romane",
  },
  {
    key: "ME",
    name: "Manuskripte Essays",
  },
  {
    key: "MW",
    name: "Manuskripte Wissenschaft",
  },
  {
    key: "N",
    name: "Notiz",
  },
  {
    key: "P",
    name: "Postkarten",
  },
  {
    key: "PR",
    name: "Prospekte",
  },
  {
    key: "RA",
    name: "Radio/TV",
  },
  {
    key: "R",
    name: "Rechnungen/Angebote",
  },
  {
    key: "S",
    name: "Sonstiges",
  },
  {
    key: "T",
    name: "Telefonat",
  },
  {
    key: "VR",
    name: "Verfasser Rezensionen",
  },
  {
    key: "V",
    name: "Verträge",
  },
  {
    key: "VK",
    name: "Visitenkarte",
  },
  {
    key: "VI",
    name: "Video",
  },
  {
    key: "Z",
    name: "Zeitungsartikel",
  },
]

export default tags

<template>
  <div :class="listClass">
    <div
      class="listWrapper"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="500"
    >
      <ListEntry
        v-for="article in articles"
        :key="article.id"
        :article="article"
        :layout="layout"
      />
    </div>
  </div>
</template>

<script>
import ListEntry from "@/components/ListEntry.vue"

export default {
  components: {
    ListEntry,
  },
  props: {
    articles: {
      type: Array,
    },
    layout: {
      type: String,
      default: "",
    },
  },
  computed: {
    listClass() {
      return `list ${this.layout}`
    },
  },
  methods: {
    loadMore(e) {
      if (e) {
        e.preventDefault()
      }
      this.$emit("onLoadMore")
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  background-image: linear-gradient(#000 1px, transparent 1px);
  background-size: 100vw 590px;
  background-position-y: -1px;

  .listWrapper {
    margin: 0 auto;
    max-width: 1501px;
    border-left: 1px solid black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 620px) {
      width: 301px;
    }
    @media (min-width: 621px) and (max-width: 920px) {
      width: 601px;
    }
    @media (min-width: 921px) and (max-width: 1240px) {
      width: 901px;
    }
    @media (min-width: 1241px) and (max-width: 1560px) {
      width: 1201px;
    }
    @media (min-width: 1520px) {
      width: 1520px;
    }
  }

  &.admin {
    width: 100% !important;
    flex-direction: column;
    flex-wrap: no-wrap;
  }
}
</style>

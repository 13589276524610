var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zoom"},[_c('div',{ref:"wrapper",staticClass:"wrapper"},[(_vm.loadedRatio > 0 && _vm.loadedRatio < 1)?_c('div',{staticStyle:{"height":"5px","background-color":"#3c8658","color":"white","text-align":"center"},style:({ width: _vm.loadedRatio * 100 + '%' })}):_vm._e(),_c('panZoom',{ref:"panzoom",staticClass:"zoomer",attrs:{"id":"zoomer","options":{
        autocenter: true,
        bounds: true,
        boundsPadding: 0.1,
        minZoom: 0.2,
        maxZoom: 5,
        smoothScroll: true,
      }},on:{"init":_vm.onInit}},[(_vm.article.type === 'image')?_c('div',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"}],ref:"img",attrs:{"src":`${_vm.path}${_vm.article.name}.jpg`,"alt":""},on:{"load":_vm.onImgLoaded}})]):_c('div',{staticClass:"pdfWrapper"},[_c('pdf',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"}],ref:"pdf",staticClass:"pdf",attrs:{"src":`${_vm.path}${_vm.article.name}-${_vm.pdfPage}.pdf`},on:{"progress":function($event){_vm.loadedRatio = $event},"error":_vm.onError,"loaded":_vm.onLoaded}})],1)])],1),_c('div',{staticClass:"row subbar flex items-center h-full"},[_c('div',{staticClass:"tags w-1/3 px-8 h-full flex items-center"},[_vm._m(0),_vm._l((_vm.article.tags),function(tag){return _c('BadgeButton',{key:tag,attrs:{"tid":tag,"color":"trans","active":"true","type":"short"}})})],2),_c('div',{staticClass:"stroke w-1/3 px-8 h-full flex items-center"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("Datum: ")]),_vm._v(_vm._s(_vm.dateString))])]),_c('div',{staticClass:"stroke buttons w-1/3 px-8"},[_c('span',[_c('RoundButton',{staticClass:"left mr-2",attrs:{"icon":"minus","color":"yellow","text":"Verkleinern"},on:{"onClick":_vm.zoomIn}}),_c('RoundButton',{staticClass:"left mr-2",attrs:{"icon":"plus","color":"yellow","text":"Vergrößern"},on:{"onClick":_vm.zoomOut}})],1),_c('RoundButton',{attrs:{"icon":"close","text":"Schließen"},on:{"onClick":function($event){return _vm.$emit('onClose')}}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"label"},[_c('span',[_vm._v("Thematik:")])])
}]

export { render, staticRenderFns }
<template>
  <button
    v-tooltip.top-center="hoverText"
    :class="clazz"
    @click.stop="$emit('onClick', tid)"
  >
    {{ badgeText }}
  </button>
</template>

<script>
import tags from '@/assets/tags.js'

export default {
  name: 'BadgeButton',
  props: {
    active: {
      type: String,
      default: 'false',
    },
    tid: {
      type: String,
    },
    text: {
      type: String,
    },
    color: {
      type: String,
      default: 'white',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tags: [],
    }
  },
  computed: {
    hoverText() {
      const tags = this.$store.state.__paginatedResources.tags.registry.default
        .items
      if (this.type === 'short' && this.tid && tags && tags.length > 0) {
        const tag = tags.find(tmp => tmp.key === this.tid)
        if (tag) {
          if (this.tid.startsWith('n_')) return `Person: ${tag.name}`
          return tag.name
        }
        return `${this.tid}` // MISSING
      }
      return null //this.text;
    },
    badgeText() {
      const tags = this.$store.state.__paginatedResources.tags.registry.default
        .items

      if (this.tid && tags && tags.length > 0) {
        const tag = tags.find(tmp => tmp.key === this.tid)
        if (tag) {
          if (this.type === 'short') {
            if (this.tid.startsWith('n_')) {
              let tmp = this.tid.split('_')
              tmp.shift()
              tmp = tmp.map(w => w.slice(0, 1)).join('')
              return `N-${tmp}`
            }
            return this.tid
          } else {
            return tag.name
          }
        }
        return this.tid // MISSING `${this.tid}`;
      }
      return this.text
    },
    clazz() {
      let cn = ['py-1', 'px-4', 'mr-0', 'text-xs', 'flex-0']
      if (this.active === 'true') cn.push(this.color)
      return cn.join(' ')
    },
  },
  beforeMount() {
    this.tags = tags.slice()
  },

  methods: {},
}
</script>

<style scoped lang="scss">
button {
  border: none;
  // padding: 6px 12px;
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  background: white;
  font-size: 12px;
  flex: 0 0 auto;

  &.orange {
    background: $orange;
    &:active {
      background: darken($orange, 10%);
    }
  }
  &.green {
    background: $green;
    &:active {
      background: darken($green, 10%);
    }
  }
  &.yellow {
    background: $yellow;
    &:active {
      background: darken($yellow, 10%);
    }
  }
  &.trans {
    background: transparent;
    border-radius: 0;
    border-bottom: 1px solid $orange;
    padding: 0;
    margin-left: 6px;
    font-size: 14px;
  }
}
</style>

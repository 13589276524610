<template>
  <header
    class="absolute w-full header border-b border-solid border-black bg-gray-100 z-50"
  >
    <div class="w-full flex items-center h-[76px] border-solid border-black">
      <div class="w-1/2 md:w-1/3 pl-8">
        <span class="mobile">EWH.</span>
        <span class="desktop">EWH. Das Archiv.</span>
      </div>
      <div
        class="w-1/2 md:w-2/3 h-full flex rounded-l-full items-center pl-8"
        :class="clazzName"
      >
        <div v-if="currentUser" class="w-0 md:w-1/2 hidden md:block">
          <a href="/admin" v-if="currentUser.username === 'admin'"
            >{{
              currentUser.username.charAt(0).toUpperCase() +
                currentUser.username.slice(1)
            }}.</a
          >
          <span v-if="currentUser.username !== 'admin'"
            >{{
              currentUser.username.charAt(0).toUpperCase() +
                currentUser.username.slice(1)
            }}.</span
          >
        </div>
        <div class="w-full md:w-1/2 flex justify-end pr-8">
          <a class="cursor-pointer" @click="logOut">Logout >>></a>
        </div>
        <!--router-link to="/import" class="nav-link">
          <RoundButton icon="import" />
        </router-link-->
      </div>
    </div>
  </header>
</template>

<script>
import { bus } from "@/main"

export default {
  components: {},
  props: {
    matches: {
      type: [Number, Boolean],
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    clazzName() {
      const un = this.currentUser.username
      if (un === "privat") {
        return "purple"
      } else if (un === "business") {
        return "green"
      } else {
        return "orange"
      }
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    logOut() {
      bus.$emit("logOut")
    },
    toggleSearch() {
      bus.$emit("showSearch")
    },
    resetSearch() {
      bus.$emit("resetSearch")
      bus.$emit("hideSearch")
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  span.mobile {
    display: none;
  }

  @media (max-width: 920px) {
    span.mobile {
      display: block;
    }
    span.desktop {
      display: none;
    }
  }
}

.purple {
  background: #8a73be;
}
.green {
  background: #418f5f;
}
.orange {
  background: #f19548;
}
.search {
  padding: 7px 0 5px;
  margin: 0 5px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid $orange;
  }
}

@media (max-width: 1023px) {
  .matchesString {
    display: none;
  }
}

@media (max-width: 767px) {
  .search {
    //display: none;
  }
}
</style>

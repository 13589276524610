<script>
// import RoundButton from "@/components/RoundButton.vue";
import BadgeButton from "@/components/BadgeButton.vue"
import { bus } from "@/main"

//import tags from "@/assets/tags.js";

export default {
  components: {
    BadgeButton,
    // RoundButton
  },
  props: {
    article: {
      type: Object,
    },
    searchObject: {
      type: Object,
    },
  },
  data() {
    return {
      selectedTags: [],
      keywords: "",
      dateString: "",
      dateFrom: [],
      dateTo: [],
      dateFilterVisible: false,
      dateFilterActive: false,
      selectionYears: [],
      selectionMonths: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],

      selectionMonthsShort: [
        "Jan",
        "Feb",
        "März",
        "Apr",
        "Mai",
        "Juni",
        "Juli",
        "Aug",
        "Sept",
        "Okt",
        "Nov",
        "Dez",
      ],
    }
  },
  computed: {
    phoneFilterActive() {
      return this.selectedTags.some(tag => tag.toUpperCase() === "IPHONE")
    },
    tags() {
      const tmp = this.$store.state.__paginatedResources.tags.registry.default.items
        .slice(0)
        .sort()
      console.log("searchBar computed tags", tmp)
      return tmp
    },
    sortedTags() {
      if (typeof this.tags !== "undefined") {
        const st = this.tags.slice(0).sort((a, b) => {
          if (a.name === undefined || b.name === undefined) return 0
          const nameA = a.name.toUpperCase() // ignore upper and lowercase
          const nameB = b.name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        return st
      }
      return []
    },
    nameTags() {
      return this.sortedTags.filter(tag => tag.type === "name")
    },
    appTags() {
      return this.sortedTags.filter(tag => tag.type === "app")
    },
    phoneTags() {
      return this.sortedTags.filter(tag => tag.type === "phone")
    },
    typeTags() {
      return this.sortedTags.filter(tag => ["AUDIO", "VIDEO"].includes(tag.key))
    },
    regularTags() {
      return this.sortedTags.filter(
        tag => !["name", "phone", "user", "app"].includes(tag.type)
      )
    },
  },
  beforeMount() {
    //this.searchObject.t.slice().forEach( (tag) => {
    //  this.selectedTags.push(tag);
    //})

    //this.selectedTags = JSON.parse(JSON.stringify(this.searchObject.t.slice())) || [];
    //debugger;

    //this.tags = tags.slice();

    let yearsArray = new Array()
    const cY = new Date().getFullYear()
    for (let i = cY; i > 1849; i--) {
      yearsArray.push(i)
    }

    this.selectionYears = yearsArray
  },
  mounted() {
    if (!this.searchObject) return

    if (this.searchObject.dfy) {
      document.getElementById("dateFromYear").value = this.searchObject.dfy
    }
    if (this.searchObject.dfm) {
      document.getElementById("dateFromMonth").value = this.searchObject.dfm
    }
    if (this.searchObject.dty) {
      document.getElementById("dateToYear").value = this.searchObject.dty
    }
    if (this.searchObject.dtm) {
      document.getElementById("dateToMonth").value = this.searchObject.dtm
    }
    bus.$on("resetSearch", () => {
      this.reset()
      //bus.$emit('search', this.searchObject);
    })
  },
  methods: {
    // eslint-disable-next-line
    onKeywordsChange(e) {
      if (e.currentTarget.value !== "") {
        e.currentTarget.classList.add("active")
        //if (e.currentTarget.value === "admin") {
        //  bus.$emit('toggleAdmin');
        //  bus.$emit('resetSearch');
        //  e.currentTarget.value = '';
        //  e.currentTarget.classList.remove('active');
        //}
      } else {
        e.currentTarget.classList.remove("active")
      }
      if ((e.type === "keyup" && e.keyCode === 13) || e.type === "blur") {
        this.search()
        bus.$emit("hideSearch")
      }
    },
    onDateChange() {
      let ds
      const fm = document.getElementById("dateFromMonth").value
      const fy = document.getElementById("dateFromYear").value
      const tm = document.getElementById("dateToMonth").value
      const ty = document.getElementById("dateToYear").value

      if (fy && ty) {
        const p1 = fm ? `${this.selectionMonthsShort[fm - 1]} ${fy}` : `${fy}`
        const p2 = tm ? `${this.selectionMonthsShort[tm - 1]} ${ty}` : `${ty}`
        ds = `${p1} - ${p2}`
      } else if (fy) {
        ds = fm ? `Von ${this.selectionMonthsShort[fm - 1]} ${fy}` : `Von ${fy}`
      } else if (ty) {
        ds = tm ? `Bis ${this.selectionMonthsShort[tm - 1]} ${ty}` : `Bis ${ty}`
      }

      if (fy || ty) {
        this.dateFilterActive = true
      } else {
        this.dateFilterActive = false
      }

      this.dateString = ds
      //e.currentTarget.classList.add('active');
    },
    toggleTag(tagId) {
      if (this.selectedTags.includes(tagId)) {
        this.selectedTags.splice(this.selectedTags.indexOf(tagId), 1)
      } else {
        this.selectedTags.push(tagId)
      }
      this.search()
      //if (tagId !== "iphone") bus.$emit("hideSearch")
    },
    toggleTimeFilter() {
      if (this.dateFilterVisible) {
        this.search()
      }
      this.dateFilterVisible = !this.dateFilterVisible
    },
    search() {
      const so = {}

      const kw = document.getElementById("searchKeywords").value
      if (kw) so.k = kw

      if (this.tags.length > 0) so.t = this.selectedTags.slice()

      const fy = document.getElementById("dateFromYear").value
      const ty = document.getElementById("dateToYear").value
      if (fy || ty) {
        so.d = {}
        if (fy) {
          so.dfy = fy
          const fm = document.getElementById("dateFromMonth").value
          if (fm) so.dfm = fm
        }
        if (ty) {
          so.dty = ty
          const tm = document.getElementById("dateToMonth").value
          if (tm) so.dtm = tm
        }
      }

      bus.$emit("search", so)
      //bus.$emit("hideSearch");
    },
    resetKeywords() {
      this.keywords = ""
      document.getElementById("searchKeywords").classList.remove("active")
    },
    reset() {
      this.selectedTags = []
      this.keywords = ""
      this.dateFilterActive = false

      this.$refs.dateFromMonth.selectedIndex = 0
      this.$refs.dateFromYear.selectedIndex = 0
      this.$refs.dateToMonth.selectedIndex = 0
      this.$refs.dateToYear.selectedIndex = 0

      this.$refs.dateFromMonth.classList.remove("active")
      this.$refs.dateFromYear.classList.remove("active")
      this.$refs.dateToMonth.classList.remove("active")
      this.$refs.dateToYear.classList.remove("active")

      document.getElementById("searchKeywords").classList.remove("active")

      this.search()
      bus.$emit("hideSearch")
    },
    close() {
      bus.$emit("hideSearch")
    },
  },
}
</script>

<template>
  <div class="searchBar bg-grey-light border-b border-black border-solid px-8">
    <div
      v-show="dateFilterVisible"
      class="timeFilterBackground"
      @click="toggleTimeFilter"
    ></div>
    <div class="search flex border-b border-solid border-black">
      <div class="searchWrapper w-full lg:w-2/3 flex items-center py-4">
        <div class="icon search"></div>
        <input
          type="text"
          id="searchKeywords"
          v-model="keywords"
          name="keywords"
          placeholder="Suche nach Schlagwort…"
          class="searchInput"
          @keyup="onKeywordsChange"
          @blur="onKeywordsChange"
          autocomplete="off"
        />
        <!--button v-show="keywords !== ''" @click="resetKeywords">x</button-->
      </div>

      <div
        class="timeFilter stroke before:hidden lg:before:block flex items-center px-0 lg:px-8 py-4 w-full lg:w-1/3 relative"
      >
        <div class="icon clock"></div>
        <BadgeButton
          v-show="!dateFilterActive"
          text="Zeitraum eingrenzen"
          @onClick="toggleTimeFilter"
          color="trans"
          :active="String(false)"
        />
        <BadgeButton
          class="timeBubble"
          v-show="dateFilterActive"
          :text="dateString"
          @onClick="toggleTimeFilter"
          color="yellow"
          :active="String(true)"
        />
        <div v-show="dateFilterVisible" class="timeFilterOverlay ">
          <div class="selects">
            <div class="fromWrapper">
              <p>Von</p>
              <select
                id="dateFromMonth"
                ref="dateFromMonth"
                @change="onDateChange"
              >
                <option disabled selected value="">Monat</option>
                <option
                  v-for="(month, index) in selectionMonths"
                  :key="month"
                  :value="index + 1"
                  :selected="searchObject.dfm === index + 1"
                >
                  {{ month }}
                </option>
              </select>
              <select
                id="dateFromYear"
                ref="dateFromYear"
                @change="onDateChange"
              >
                <option disabled selected value="">Jahr</option>
                <option
                  v-for="year in selectionYears"
                  :key="year"
                  :value="year"
                  :selected="searchObject.dfy === year"
                >
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="fromWrapper mb-4">
              <p>Bis</p>
              <select id="dateToMonth" ref="dateToMonth" @change="onDateChange">
                <option disabled selected value="">Monat</option>
                <option
                  v-for="(month, index) in selectionMonths"
                  :key="index"
                  :value="index + 1"
                  :selected="searchObject.dtm === index + 1"
                >
                  {{ month }}
                </option>
              </select>
              <select id="dateToYear" ref="dateToYear" @change="onDateChange">
                <option disabled selected value="">Jahr</option>
                <option
                  v-for="year in selectionYears"
                  :key="year"
                  :value="year"
                  :selected="searchObject.dty === year"
                >
                  {{ year }}
                </option>
              </select>
            </div>
            <BadgeButton
              class="close"
              text="Zeitauswahl schließen"
              @onClick="toggleTimeFilter"
              color="trans"
              :active="String(true)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tags pt-8 pb-4 px-2">
      <BadgeButton
        v-for="tag in regularTags"
        :text="tag.name || tag.key"
        :data="tag.key"
        :key="tag.key"
        :tid="tag.key"
        @onClick="toggleTag"
        class="mb-3 mr-4"
        color="yellow"
        :active="String(selectedTags.includes(tag.key))"
      />
    </div>
    <div class="tags pb-2" v-if="phoneFilterActive">
      <div class="border-t border-black border-solid w-full pt-4 pb-2 px-2">
        <BadgeButton
          v-for="tag in phoneTags"
          :text="tag.name || tag.key"
          :data="tag.key"
          :key="tag.key"
          :tid="tag.key"
          @onClick="toggleTag"
          class="mb-3 mr-4"
          color="yellow"
          :active="String(selectedTags.includes(tag.key))"
        />
      </div>
      <div
        class="border-t border-black border-solid w-full pt-4 pb-2 px-2"
        v-if="appTags.length > 0"
      >
        <BadgeButton
          v-for="tag in appTags"
          :text="tag.name || tag.key"
          :data="tag.key"
          :key="tag.key"
          :tid="tag.key"
          @onClick="toggleTag"
          class="mb-3 mr-4"
          color="yellow"
          :active="String(selectedTags.includes(tag.key))"
        />
      </div>
      <div class="border-t border-black border-solid w-full pt-4 pb-2 px-2">
        <BadgeButton
          v-for="tag in nameTags"
          :text="tag.name || tag.key"
          :data="tag.key"
          :key="tag.key"
          :tid="tag.key"
          @onClick="toggleTag"
          class="mb-2 mr-4"
          color="yellow"
          :active="String(selectedTags.includes(tag.key))"
        />
      </div>
    </div>
    <div class="flex justify-center">
      <BadgeButton
        class="close mb-8 mr-3 grow-0 shrink-0"
        @onClick="close"
        text="Suchmaske schließen"
        color="trans"
        :active="String(true)"
      />
      <BadgeButton
        class="close mb-8 ml-3 grow-0 shrink-0"
        @onClick="reset"
        text="Alle Filter zurücksetzen"
        color="trans"
        :active="String(true)"
      />
    </div>

    <!--div class="row searchNav">
      <div>
        <div class="clickWrapper" @click="reset">
          <RoundButton icon="reset" text="Zurücksetzen" />
          <span>Reset</span>
        </div>
      </div>
      <div class="center">
        <RoundButton @onClick="close" icon="up" text="Schließen" />
      </div>
      <div class="right">
        <div class="clickWrapper" @click="search">
          <span>Go!</span>
          <RoundButton icon="search" text="Suchen" />
        </div>
      </div>
    </div-->
  </div>
</template>

<style scoped lang="scss">
.searchBar {
  position: fixed;
  top: 76px;
  left: 0;
  z-index: 2;
  width: 100%;
  //height: auto;
  transition: top 0.4s;

  &.hid {
    top: -720px;
  }
}

.timeFilter {
  position: relative;

  button {
    font-size: 16px;
    background: transparent;

    &.yellow {
      background: $yellow;
    }
  }
}

.timeFilterOverlay {
  position: absolute;
  top: $lineHeightL - 12px;
  left: -2rem;
  right: 0;
  width: calc(100% + 4rem);
  height: auto;
  background: $grey_light;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  padding: 0 3rem 1rem;
  z-index: 99;

  .fromWrapper,
  .toWrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;

    height: 75px;

    p {
      display: inline-block;
      font-size: 16px;

      @media (max-width: $mobileBreakpoint) {
        font-size: 16px;
      }
    }
  }
}

.searchWrapper {
  input {
    border: none;
    padding: 6px 12px;
    font-size: 16px;
    background: transparent;
    border-radius: 20px;
    outline: none;
    max-width: 280px;
    width: calc(100% - 90px);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $grey inset !important;
    }

    &::placeholder {
      color: black;
    }

    &:focus {
      &::placeholder {
        color: #777;
      }
    }

    &.active {
      background: $yellow;
    }

    @media (max-width: $mobileBreakpoint) {
      font-size: 16px;
      width: 100%;
    }
  }
}

.clickWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
}

select {
  border: none;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 28px;
  background: transparent;
  outline: none;
  appearance: none;
  margin-right: 5px;
  text-align-last: center;

  &.active {
    background: $yellow;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 16px;
  }
}

option {
  font-size: 12px;
}

@media (max-width: 1024px) {
  .searchBar {
    .search {
      flex-direction: column;
    }

    &.hid {
      top: -150vh;
    }
  }
}

@media (max-width: 952px) {
  .timeFilter {
    .label {
      display: none;
    }
  }
}

@media (max-width: 1023px) {
  .clickWrapper {
    span {
      display: none;
    }
  }

  .row.tags,
  .row.search {
    flex-direction: column;
    height: auto;

    & > div {
      width: 100% !important;
      height: 75px;
    }

    .label {
      display: none;
    }

    .stroke {
      &:before {
        display: none;
      }
    }

    input {
      margin-bottom: 7px;
      width: calc(100% - 90px);
    }
  }

  .row.tags {
    flex-direction: column;

    & > div {
      width: 100%;

      &:before {
        display: none;
      }
    }
  }

  .row.tags > div {
    height: auto;
  }

  p {
    padding: 10px 10px 10px 0 !important;
  }

  select {
    //width: 100%;
    text-align-last: center;
  }

  .timeFilterOverlay {
    border-left: none;
    height: auto;
  }

  .timeFilter {
    border-top: 1px solid black;
  }

  .fromWrapper {
    height: 52px;
  }
}
</style>
